.category-icon{
  width: fit-content;
  padding: 0.5rem;
  border-radius: 9px;
  background: transparent linear-gradient(227deg, #F15754 0%, #B7302E 100%) 0% 0% no-repeat padding-box;

  &-white{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(227deg, var(--uni-grey-200) 0%, var(--uni-grey-300) 100%) 0% 0% no-repeat padding-box;

    img{
      filter: invert(50%) brightness(80%);
    }
  }

  &-blue{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(216deg, #01A9F0 0%, #0357A5 100%) 0% 0% no-repeat padding-box;
  }

  &-green{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(227deg, #44C18A 0%, #1C704C 100%) 0% 0% no-repeat padding-box;
  }

  &-yellow{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(227deg, #F9C057 0%, #DB9537 100%) 0% 0% no-repeat padding-box;
  }

  &-red{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(227deg, #F15754 0%, #B7302E 100%) 0% 0% no-repeat padding-box;
  }

  &-purple{
    width: fit-content;
    padding: 0.5rem;
    border-radius: 9px;
    background: transparent linear-gradient(227deg, #BC00F4 0%, #8400AB 100%) 0% 0% no-repeat padding-box;
  }
}
