.uni--select {
  background-color: transparent;

  color: var(--uni-contrast-500);
  border-radius: 2rem;
  border: 1px solid var(--uni-contrast-500);

  min-height: 3.5rem;
  height: 3.5rem;

  position: relative;

  padding: 1rem 1.5rem;
  box-shadow: 0 3px 6px #00000029;

  font-size: 1rem;

  nz-select-placeholder {
    color: var(--uni-grey-300);
  }

  nz-select-top-control {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;

    padding-left: 0 !important;
  }

  nz-select-search {
    left: 0 !important;
  }

  nz-select-item, nz-select-placeholder {
    line-height: 1.5rem !important;
  }

  nz-select-arrow {
    right: 24px;
    color: var(--uni-contrast-500);
    font-size: 1rem;
    vertical-align: 0;
    top: calc(50% - .5rem);

    height: 1rem;
    width: 1rem;

    margin-top: 0;
  }
}
