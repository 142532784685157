@import "~node_modules/ng-zorro-antd/style/entry";
@import "~node_modules/ng-zorro-antd/button/style/entry";
@import "~node_modules/ng-zorro-antd/spin/style/entry";
@import "~node_modules/ng-zorro-antd/input/style/entry";
@import "~node_modules/ng-zorro-antd/icon/style/entry";
@import "~node_modules/ng-zorro-antd/checkbox/style/entry";
@import "~node_modules/ng-zorro-antd/notification/style/entry";
@import "~node_modules/ng-zorro-antd/rate/style/entry";
@import "~node_modules/ng-zorro-antd/slider/style/entry";
@import "~node_modules/ng-zorro-antd/modal/style/entry";
@import "~node_modules/ng-zorro-antd/select/style/entry";
@import "~node_modules/ng-zorro-antd/alert/style/entry";
@import "~node_modules/ng-zorro-antd/carousel/style/entry";
@import "~node_modules/ng-zorro-antd/popover/style/entry";
@import "./themes/default";

@import "components/index";

:root {
  --uni-light-100: #FFFFFF;
  --uni-light-150: #91949b;
  --uni-light-200: #A7A6A7;
  --uni-light-250: #f6f8fa;
  --uni-light-400: #004479;
  --uni-light-500: #FFF;
  --uni-light-600: #0060ac;
  --uni-light-700: #f6f8fa;
  --uni-light-750: #eeeeef;
  --uni-light-800: #eeeeef;

  --uni-contrast-300: #4c5975;
  --uni-contrast-500: #242424;
  --uni-primary-contrast-500: #FFFFFF;

  --uni-primary-400: #01a8ec;
  --uni-primary-500: #469EC3;
  --uni-primary-800: #004479;
  --uni-primary-900: #033A65;

  --uni-black: #212429;
  --uni-grey-400: #495057;
  --uni-grey-300: #ACB5BD;
  --uni-grey-200: #DDE2E5;
  --uni-grey-100: #F8F9FA;
  --uni-blue: #0357A5;
  --uni-light-blue: #01A9F0;
  --uni-yellow: #DB7D00;
  --uni-light-yellow: #F9A200;
  --uni-purple: #8400AB;
  --uni-light-purple: #BC00F4;
  --uni-green: #017040;
  --uni-light-green: #00C16D;
  --uni-red: #B72A28;
  --uni-light-red: #F15B59;
}
