.switch__container {
  margin: 30px auto;

  .switch {
    visibility: hidden;
    position: absolute;
    margin-left: -9999px;

    & + label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      user-select: none;
    }
  }

  .switch--shadow:checked + label:before {
    color: var(--uni-grey-100);
  }

  .switch--shadow:checked + label:after {
    transform: translateX(60px);
  }

  .switch--shadow + label {
    padding: 2px;
    width: 120px;
    height: 60px;
    background-color: #dddddd;
    border-radius: 60px;

    &:before,
    :after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
    }

    &:before {
      right: 1px;
      border-radius: 60px;
      transition: background 0.4s;
    }

    &:after {
      width: 62px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      transition: all 0.4s;
    }
  }
}

.switch--flat + label {
  padding: 2px;
  width: 200px;
  height: 50px;
  border-radius: 60px;
  transition: background 0.4s;
  font-weight: lighter;
}

.switch--flat + label:before,
.switch--flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

.switch--flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: var(--uni-black-400);
  border-radius: 60px;
  transition: background 0.4s;
  display: flex;
  justify-content: space-between;
}

.switch--flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 100px;
  background-color: var(--uni-red-200);
  border-radius: 52px;
  transition: margin 0.4s, background 0.4s;
  content: "Entrar";
  color: var(--uni-grey-100);
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.switch--flat:checked + label:after {
  margin-left: 92px;
  background-color: var(--uni-red-200);
  content: "Registrar";
  color: var(--uni-grey-100);

  display: flex;
  align-items: center;
  justify-content: center;
}

.switch__container label .can-toggle__switch:before {
  left: 100px;
  top: 6px;
  font-size: 12px;
  line-height: 36px;
  width: 100px;
  padding: 0 12px;
}

.switch__container label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 14px;
}

.switch__container label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 7px;
  text-align: center;
  font-size: 14px;
}

.switch__container label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  top: 10px;
  left: 20px;
  text-align: center;
  transform: translate3d(0, 0, 0);
}
