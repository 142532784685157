.uni--profile--header {
  display: flex;
  align-items: center;

  margin-bottom: 1.5rem;

  h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: "Segoe UI";

    margin-bottom: 0;

    span {
      font-weight: bold;
      font-family: "Segoe UI";

      font-size: 1.5rem;
    }
  }

  hr {
    flex: 1;

    border-radius: 8px;

    height: 0.25rem;

    margin-left: 1.5rem;

    border: none;
    background-color: var(--uni-red-100);
  }

  a {
    border-radius: 50%;
    background-color: var(--uni-light-100);

    min-height: 2rem;
    height: 2rem;
    min-width: 2rem;
    width: 2rem;

    margin-left: 1.5rem;

    &.uni--header--a__invert-margin {
      margin-left: 0;
      margin-right: 1.5rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 1.25rem;
    }
  }
}
