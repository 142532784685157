.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212429;
  width: fit-content;
  min-width: 7rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  height: 2.5rem;
  box-shadow: 0 0 20px 2px rgba(0,0,0,0.2);
  margin: 1rem 0;

  @media (max-width: 900px) {
    margin: 0.2rem 0;
  }

  &--bullet {
    display: block;
    background: #de4242;
    border-radius: 100%;
    margin: 0 1rem 0 0;
    height: 0.5rem;
    width: 0.5rem;
  }

  &--live {
    background: #de4242;
  }

  &--online {
    background: #027BC5;
  }

  &--presential {
    background: #0D0D0D;
  }

  &--title {
    padding: 0;
    margin: 0;
    font-size: small;

    &--time {
      padding: 0 1rem;
      margin: 0;
    }
  }

  &--star {
    font-size: small;
  }
}

// mudar cor preenchimento estrela
.ant-rate-star-full path:first-child{
  fill: var(--uni-yellow-100) !important;
}

.ant-rate-star-full path:last-child{
  fill: var(--uni-yellow-100) !important;
}

.ant-rate-star-first path:first-child{
  fill: var(--uni-yellow-100) !important;
}

.ant-rate-star-half path:last-child{
  fill: var(--uni-yellow-100) !important;
}

// retirar preenchimento da estrela
.ant-rate-star-zero{
  path:first-child {
    display: none !important;
  }
  // mudar cor da estrela sem preenchimento
  path:nth-child(2){
    fill: white !important;
  }
}
