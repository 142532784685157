.uni--buttonold {
  width: 100%;
  --background: var(--uni-color-blue-dark);
  color: white;

  &__outline {
    margin-top: 2rem;
    border: var(--uni-color-blue-dark);
    --background: white;
    color: var(--uni-color-blue-dark);
    border: 1px solid var(--uni-color-blue-dark);
    border-radius: 4px;
  }
}

.uni-link-button {
  outline: none;
  border: none;
  background: none;

  min-height: 3rem;
  height: 3rem;

  border-radius: 1.5rem;

  color: #acb5bd;
  opacity: 0.65;

  padding-left: 3rem;
  padding-right: 3rem;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}

.uni--button {
  outline: none;
  border: none;

  min-height: 3rem;
  height: 3rem;

  border-radius: 37px;
  background-color: var(--uni-red-100);

  &:hover {
    box-shadow: 0 3px 6px #0000003b;
  }

  &:focus,
  &:active {
    background-color: var(--uni-primary-800);
  }

  padding-left: 3rem;
  padding-right: 3rem;

  &__free-courses {
    background-color: var(--uni-free-course-primary);
    border: 1px solid var(--uni-free-course-dark);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--uni-free-course-secondary);
    }
  }

  &__small {
    min-height: 2rem;
    height: 2rem;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}
