.uni--nav {
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  font-family: "Open Sans", sans-serif;

  ul {
    display: flex;

    list-style: none;

    padding-left: 0;
    margin: 0;

  }

  li {
    padding-right: 2rem;
  }

  a {
    font-size: 1rem;

    color: var(--uni-grey-300);

    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
  }

  &__selected{
    color: var(--uni-contrast-500) !important;
  }
}


.background--domestika{
  background-color: #F02D00 !important;
}
