.uni--new-input {
  background-color: transparent;
  margin-bottom: 20px;

  min-height: 3.5rem;
  height: 3.5rem;

  position: relative;

  padding: 1rem 1.5rem;
  border-radius: 37px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--uni-grey-100);

  transition: none !important;

  .ant-input-affix-wrapper,
  .ant-input {
    transition: none !important;
  }

  input {
    font-size: 1rem;
    background-color: transparent;

    padding: 0;
    border: none;

    &::placeholder {
      -webkit-text-fill-color: var(--uni-contrast-300) !important;
      color: var(--uni-grey-100) !important;
      background-color: transparent !important;
    }

    &,
    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--uni-contrast-500) !important;
      color: var(--uni-grey-300) !important;
      background-color: transparent !important;
    }

    &[type="text"]:hover,
    &[type="text"]:focus {
      outline: none !important;
    }

    &:disabled,
    &:read-only {
      opacity: 0.6;
    }
  }

  nz-spin {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    z-index: 10;
  }

  .ant-input-prefix {
    margin-right: 0.5rem;
  }

  .ant-input-group-addon {
    background-color: transparent;
    border: none;

    & > * {
      margin-right: -2.2rem;
      margin-top: -1rem;
    }
  }
}

.uni--box-label-float {
  position: relative;
  padding-top: 13px;
  width: var(--uni-input-width, 330px);

  display: flex;
  align-items: flex-end;

  .uni--input-text,
  .login--input-password {
    width: var(--uni-input-width, 330px);
    border: 0;
    border-bottom: 1px solid var(--uni-grey-300);

    background-color: transparent;

    outline: none;
    font-size: 15px;
    padding-left: 1rem;

    margin-top: 20px;

    -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    appearance: none;
    -webkit-appearance: none;

    min-height: 2.5rem;
    height: 2.5rem;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px transparent inset; // Cor do fundo
      -webkit-text-fill-color: var(--uni-grey-300); /* Cor do texto */
    }

    &:focus {
      border-bottom: 1px solid var(--uni-primary-400) !important;
    }

    &::placeholder {
      color: transparent;
    }
  }

  .uni--label {
    position: absolute;
    top: 20px;
    left: 10px;
    margin-top: 20px;
    font-weight: lighter;
    font-size: 15px;

    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
  }

  .uni--input-text:required:invalid + .uni--label {
    color: var(--uni-grey-300);
  }

  .uni--input-text:focus:required:invalid {
    border-bottom: 1px solid var(--uni-grey-300);
  }

  .uni--input-text:required:invalid + .uni--label {
    content: "*";
  }

  .uni--input-text:-webkit-autofill + .uni--label,
  .uni--input-text:focus + .uni--label,
  .uni--input-text:not(:placeholder-shown) + .uni--label {
    font-size: 12px;
    margin-top: 0;
    color: var(--uni-grey-300);
  }
}

.password-input {
  padding-right: 2.8rem;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--uni-primary-400);
}

.ant-picker-today-btn {
  color: var(--uni-primary-800);

  &:hover {
    color: var(--uni-primary-400);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--uni-primary-400);
}

.ant-picker {
  box-shadow: none;
}
