.uni--checkbox-container {
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;

  .uni--checkbox-label {
    display: block;
    align-items: center;
    position: relative;
    padding-left: 25px;
    font-size: 14px;
    font-weight: lighter;
    color: var(--uni-grey-100);

    .uni--checkbox {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: var(--uni-red-200);
        border: none;
        opacity: 1;

        -webkit-animation: fadeOpacity 1s ease-in-out;
        -moz-animation: fadeOpacity 1s ease-in-out;
        -o-animation: fadeOpacity 1s ease-in-out;
        animation: fadeOpacity 1s ease-in-out;
      }

      &:disabled ~ .checkmark {
        background-color: var(--uni-grey-200);
        border: none;
      }

      &:checked ~ .checkmark::after {
        display: block;
      }
    }

    .checkmark::after {
      content: "";
      left: 6px;
      top: 0;
      width: 8px;
      height: 17px;
      transform: rotate(45deg);
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid var(--uni-grey-100);
      border-radius: 2px;

      &::after {
        display: none;
        content: "";
        position: absolute;
      }
    }
  }
}

@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
