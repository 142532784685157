.uni--fieldset {
  padding-bottom: 0.5rem;

  legend {
    border-color: var(--uni-red-100);
  }

  ul {
    padding-left: 0;

    list-style: none;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subscription-period {
    font-size: 0.85rem;
  }

  p,
  label {
    font-weight: normal;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  p {
    margin-left: 0.25rem;
  }

  label {
    margin-right: 0.25rem;
  }

  h4 {
    margin: 0;
  }
}
