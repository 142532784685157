.uni--modal--no-content {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.uni--modal--default {
  .ant-modal-content {
    border-radius: 20px;
    padding: 1.5rem;
  }
}

.create-note-modal {
  .ant-modal-content {
    background-color: var(--uni-black-500);
    border-radius: 16px;
    padding: 23px 37px;
  }

  .ant-modal-close-x {
    font-weight: 900;
    color: var(--uni-red-100);
  }
}

.uni--modal--default .ant-modal-content {
  background: radial-gradient(at top, #212429 10%, #101010 50%);
}
