.uni-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 8px;

  &--content {
    display: flex;
    align-items: center;

    &--icon {
      width: 20px;
      margin-right: 20px;
    }

    &--text {
      font-size: 17px;
      font-weight: bold;

      span {
        font-size: 15px;
        font-weight: normal;
        color: var(--uni-grey-200) !important;
      }
    }
  }

  &--icon {
    width: 30px;
    opacity: 70%;
    cursor: pointer;
  }

  &-type-notification {
    border: 0.5px solid var(--uni-red-100);
    background: rgb(29, 66, 82);
    background: linear-gradient(
      90deg,
      rgba(29, 66, 82, 1) 0%,
      rgba(31, 34, 39, 1) 51%,
      rgba(21, 22, 26, 1) 100%
    );

    h3 {
      color: var(--uni-red-200);
    }
  }

  &-type-success {
    border: 0.5px solid var(--uni-green-100);
    background: rgb(29, 82, 58);
    background: linear-gradient(
      90deg,
      rgba(29, 82, 58, 1) 0%,
      rgba(31, 34, 39, 1) 51%,
      rgba(21, 22, 26, 1) 100%
    );

    h3 {
      color: var(--uni-green-200);
    }
  }

  &-type-error {
    border: 0.5px solid var(--uni-red-100);
    background: rgb(82, 29, 28);
    background: linear-gradient(
      90deg,
      rgba(82, 29, 28, 1) 0%,
      rgba(31, 34, 39, 1) 51%,
      rgba(21, 22, 26, 1) 100%
    );

    h3 {
      color: var(--uni-red-200);
    }
  }

  &-type-warning {
    border: 0.5px solid var(--uni-yellow-100);
    background: rgb(81, 63, 29);
    background: linear-gradient(
      90deg,
      rgba(81, 63, 29, 1) 0%,
      rgba(31, 34, 39, 1) 51%,
      rgba(21, 22, 26, 1) 100%
    );

    h3 {
      color: var(--uni-yellow-200);
    }
  }
}
