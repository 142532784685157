.tooltip {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  opacity: 100;
  margin-top: 80px;

  svg {
    width: 20px;
    fill: var(--uni-grey-100);
    margin-left: 50px;

    &:hover {
      fill: var(--uni-red-200);
    }
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: var(--uni-black-100);
  color: var(--uni-grey-100);
  text-align: left;
  padding: 20px;
  border-radius: 6px;
  min-width: 110px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 0%;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--uni-black-100) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
