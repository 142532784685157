.fix-inner-html {
  iframe {
    max-width: 100%;
  }

  p, span, label, h1, h2, h3, h4, h4, h5, h6 {
    color: var(--uni-contrast-500) !important;
    background: none !important;
    word-break: break-word;
  }

  a {
    color: var(--uni-primary-400) !important;
    background: none !important;
    word-break: break-word;
  }
}
