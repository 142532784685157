.uni--new-select {
  position: relative;
  width: 250px;
  margin-right: 1rem;
  margin-top: 30px;

  font-size: 0.9rem;

  &[open] {
    z-index: 1;
  }

  summary {
    padding: 1rem;
    cursor: pointer;
    border-radius: 5px;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: var(--uni-black-400);
    color: var(--uni-grey-200);
    min-height: 2.5rem;
    height: 2.5rem;
    border-radius: 37px;
    border: 1px solid var(--uni-grey-100);
  }

  summary::-webkit-details-marker {
    display: none;
  }

  &[open] summary:before {
    content: "";
    display: block;
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
  }

  summary:after {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
    border-bottom-left-radius: 2px;
    transform-origin: center center;
    transition: transform ease-in-out 100ms;
    transform: rotate(-45deg) translate(0%, 0%);
  }

  summary:focus {
    outline: none;
  }

  &[open] summary:after {
    transform: rotate(135deg) translate(50%, 0%);
    color: var(--uni-red-200);
  }

  ul {
    width: 100%;
    background: var(--uni-black-100);
    color: var(--uni-grey-300);
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    margin: 0;
    box-sizing: border-box;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    z-index: 2;
  }

  li {
    margin: 0;
    height: 3rem;
    padding: 10px 20px;

    &:hover {
      background-color: var(--uni-grey-400);
      color: var(--uni-grey-100);
    }

    &:active {
      background-color: var(--uni-red-200);
      color: var(--uni-grey-100);
    }
  }
}
