.uni--form {
  &--item {
    width: 100%;
    margin-bottom: 1.5rem;

    small {
      display: inline-block;

      margin-top: 1rem;

      text-align: center;
    }

    nz-select {
      width: 100%;
    }
  }

  .uni--input {
    input {
      &.ng-touched.ng-invalid {
        -webkit-text-fill-color: red !important;
        color: red !important;
      }
    }
  }
}
