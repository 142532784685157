.uni--input {
  background-color: var(--uni-light-400);

  min-height: 3.5rem;
  height: 3.5rem;

  position: relative;

  padding: 1rem 1.5rem;
  border-radius: 37px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;

  transition: none !important;

  .ant-input-affix-wrapper, .ant-input {
    transition: none !important;
  }

  input {
    font-family: 'Segoe UI';
    font-size: 1rem;

    padding: 0;
    border: none;

    &::placeholder {
      -webkit-text-fill-color: var(--uni-contrast-300) !important;
      color: var(--uni-contrast-300) !important;
      background-color: var(--uni-light-400) !important;
      -webkit-box-shadow: 0 0 0 30px var(--uni-light-400) inset !important;
    }

    &,
    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--uni-contrast-500) !important;
      color: var(--uni-contrast-500) !important;
      background-color: var(--uni-light-400) !important;
      -webkit-box-shadow: 0 0 0 30px var(--uni-light-400) inset !important;
    }

    &[type="text"]:hover, &[type="text"]:focus {
      outline: none !important;
    }

    &:disabled, &:read-only {
      opacity: .6;
    }
  }

  nz-spin {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    z-index: 10;
  }

  .ant-input-prefix {
    margin-right: .5rem;
  }

  .ant-input-group-addon {
    background-color: transparent;
    border: none;

    & > * {
      margin-right: -2.2rem;
      margin-top: -1rem;
    }
  }
}
