.hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-height: none;
}

.uni--scrollbar {
  --thumb-color: var(--scrollbar-thumb-color, var(--uni-red-100));
  --thumb-background: var(--scrollbar-thumb-background, var(--uni-light-200));

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px var(--thumb-background);
    border-radius: 0.6rem;
    background-color: var(--thumb-background);
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 0.6rem;
    background-color: var(--thumb-background);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    -webkit-box-shadow: inset 0 0 4px var(--thumb-color);
    background-color: var(--thumb-color);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--uni-red-200) var(--uni-light-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--uni-light-800);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--uni-primary-800);
  border-radius: 20px;
}
