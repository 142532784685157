.dropdown {
  position: relative;
  display: inline-block;
  background-color: var(--uni-black-100);
  top: 0;

  button {
    background-color: transparent;
    border: none;
    width: 75px;
    height: 60px;
    padding-top: 5px;

    .icon--notifications {
      width: 20px;
    }

    .icon--arrow {
      width: 10px;
    }

    .user--avatar {
      width: 35px;
      margin-right: 10px;
      border-radius: 50%;

      object-fit: cover;

      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
    }

    @media (max-width: 426px) {
      width: 50px;
      margin-left: 10px;
    }
  }

  &--notifications {
    border-bottom-left-radius: 20px;

    button {
      width: 60px;
    }
  }

  &--profile {
    border-bottom-right-radius: 20px;
    border-left: 0.5px solid var(--uni-grey-400);
  }

  &--icons {
    width: 20px;
    margin-right: 20px;
  }
}

.dropdown-content {
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: 62px;
  background-color: var(--uni-black-100);
  min-width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  animation: fadeIn 0.3s linear;

  a,
  a img {
    opacity: 1 !important;
  }

  &-notifications {
    padding: 20px;
    min-width: 500px;
    background: rgb(21, 22, 26);
    background: linear-gradient(
      90deg,
      rgba(21, 22, 26, 1) 0%,
      rgba(33, 36, 41, 1) 50%,
      rgba(21, 22, 26, 1) 100%
    );

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      span {
        font-size: 14px;
      }
    }

    &--footer {
      width: 100%;
      text-align: center;
      opacity: 75%;

      span {
        font-size: 14px;
      }
    }
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  opacity: 80%;
  transition: background 0.3s;
  transition-timing-function: linear;

  img {
    opacity: 50%;
  }

  &:last-child {
    border-top: 1px solid var(--uni-grey-400);
  }

  &:first-child {
    border-radius: 20px 20px 0 0;
  }

  &:last-child {
    border-radius: 0 0 20px 20px;
  }

  &:hover {
    background-color: var(--uni-grey-400);
    opacity: 90%;

    img {
      opacity: 90%;
    }

    &:first-child {
      border-radius: 20px 20px 0 0;
    }

    &:last-child {
      border-radius: 0 0 20px 20px;
    }
  }
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
