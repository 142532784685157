.uni--new-button {
  transition: ease-in 0.3s;

  min-height: 3rem;
  height: 3rem;

  border-radius: 37px;
  border: none;
  background-color: transparent;

  text-transform: uppercase;
  color: var(--uni-red-200);
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--uni-primary-400);
    background: var(--uni-primary-400);
  }

  padding-left: 3rem;
  padding-right: 3rem;

  &__free-courses {
    background-color: var(--uni-free-course-primary);
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--uni-free-course-secondary);
    }
  }

  &__small {
    min-height: 2rem;
    height: 2rem;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &-news {
    &:hover,
    &:active,
    &:focus {
      color: var(--uni-red-200) !important;
      background-color: var(--uni-black-400) !important;
      box-shadow: none !important;
    }
  }
}

button {
  cursor: pointer !important;
}

.uni--primary-button {
  transition: ease-in 0.3s;

  color: var(--uni-grey-100);
  background-color: var(--uni-primary-400);
  border-radius: 25px;
  border: none;

  text-align: center;
  font-size: 0.9rem;

  min-width: 12rem;
  width: 12rem;
  min-height: 2.5rem;
  height: 3rem;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--uni-primary-400);
    background: var(--uni-primary-400);
  }

  &:hover {
    -webkit-box-shadow: 0 0 10px 1px rgba(255, 0, 0, 0.822);
    box-shadow: 0 0 10px 1px rgba(255, 2, 2, 0.644);
  }

  &:active {
    box-shadow: none;
    background-color: var(--uni-red-400);
    transition: none;
  }

  &:disabled {
    background-color: var(--uni-grey-400);
    color: var(--uni-grey-300);

    &:hover {
      box-shadow: none;
    }
  }
}

.uni--secondary-button {
  transition: ease-in 0.3s;

  color: var(--uni-primary-400);
  background-color: transparent;
  border-radius: 25px;
  border: 2px solid var(--uni-primary-400);
  margin: 10px;

  text-align: center;
  font-size: 0.9rem;

  min-width: 8rem;
  width: 9rem;
  min-height: 2.7rem;
  height: 3rem;

  &:hover {
    color: var(--uni-grey-100);
    background: var(--uni-primary-400);
  }

  &:focus,
  &:active {
    box-shadow: none;
    background-color: var(--uni-primary-300);
    color: var(--uni-grey-100);
    transition: none;
    border: 0;
  }

  &:disabled {
    border: 1px solid var(--uni-grey-400);
    color: var(--uni-grey-100);

    &:hover {
      box-shadow: none;
    }
  }
}
